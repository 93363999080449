import React, { useState } from "react"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import NewLayout from "../components/new_layout"
import Timeline from "../components/UI/Timeline/timeline"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { getYouTubeEmbedUrl } from "../utils/gatsby-helpers"
import Clippings from "../components/v2023/Clippings/clippings"

const AboutTecalis = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page.edges[0].node
    const [videoModal, setVideoModal] = useState(false)
    const [videoLoading, setVideoLoading] = useState(true)

    const [showHowWeDoItem, setShowHowWeDoItem] = useState(-1)

    const selectShowHowWeDoItem = (index) => {
        if (showHowWeDoItem === index) {
            setShowHowWeDoItem(-1)
        } else {
            setShowHowWeDoItem(index)
        }
    }

    const openVideoModal = () => {
        setVideoModal(!videoModal)
    }

    const spinnerVideo = () => {
        setVideoLoading(!videoLoading)
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg">
                    <div className="container">
                        <div className="container__50">
                            <h1 className="merriweather">
                                {page.title}
                            </h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <ReactMarkdown children={page.description_list} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <div className="container__50__media">
                                <GatsbyImage alt={page.video_preview.alternativeText} image={getImage(page.video_preview.localFile)} />
                                <a onClick={openVideoModal} className="button-icon" target="_blank" rel="noreferrer">
                                    <i className="icon-play"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section">
                    <div className="container">
                        <div className="grid-sm-4">
                            {page.metrics.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="stat" key={index} {...animation}>
                                    <div className="title-xl"> + {item.number}</div>
                                    <span>{item.name}</span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.pillars_title}</h2>
                        <div className="grid-sm-2">
                            {page.pillars_items.map((item, index) => (
                                <div className="numbered-list" key={index}>
                                    <div className="numbered-list__number">{item.order}</div>
                                    <div className="numbered-list__info">
                                        <h4>{item.title}</h4>
                                        <div className="numbered-list__info__text">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100 color-section gray">
                    <div className="container">
                        <h2>{page.why_title}</h2>
                        <div className="container__text">
                            {page.why_subtitle}
                        </div>
                        <div className="grid-lg-3">
                            {page.why_items.map((item, index) => (
                                <div className="image-group" key={index}>
                                    <img src={item.icon.localFile.publicURL} alt={item.icon.alternativeText} />
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <ReactMarkdown children={page.culture_title_new} rehypePlugins={[rehypeRaw]} />
                        <div className="container__text">
                            {page.culture_subtitle}
                        </div>
                        <div className="grid-lg-3-h">
                            {page.culture_items.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="image-group" key={index} {...animation}>
                                    <GatsbyImage alt={item.icon.alternativeText} image={getImage(item.icon.localFile)} className="" />
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--md color-section green">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.corporate_policies_title}</h2>
                            <div className="item-list">
                                {page.corporate_policies.map((item, index) => (
                                    <div className={`item ${showHowWeDoItem === index ? "item--active" : ""}`} key={index}>
                                        <div className="item__header" onClick={() => selectShowHowWeDoItem(index)}>
                                            <div className="item__header__title">{item.name}</div>
                                            <i className="icon-chevron-down"></i>
                                        </div>
                                        <div className="item__body">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                            {item.link &&
                                                <Link to={item.link}>
                                                    <button className="button button--xs">{item.button}</button>
                                                </Link>
                                            }
                                            {item.pdf &&
                                                <a href={item.pdf.localFile.publicURL} target="_blank">
                                                    <button className="button button--xs">{item.button}</button>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <GatsbyImage alt={page.corporate_policies_image.alternativeText} image={getImage(page.corporate_policies_image.localFile)} />
                        </div>
                    </div>
                </div>

                <Clippings lang={lang} />

                <div className="main__section main__section--100 hidden-sm">
                    <div className="container">
                        <h2>{page.history_title}</h2>
                        <Timeline history_items={page.history_items} />
                    </div>
                </div>
                <ReadyToTalk lang={lang} />
            </main>
            {videoModal &&
                <div className="tc-modal">
                    <div className="tc-modal__content">
                        <div className="tc-modal__content__header">
                            <button className="button-icon button-icon--sm" onClick={openVideoModal}>
                                <i className="icon-close"></i>
                            </button>
                        </div>
                        <div className="tc-modal__content__body">
                            <iframe
                                onLoad={spinnerVideo}
                                loading="lazy"
                                src={getYouTubeEmbedUrl(page.video_url)}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="true"
                            />
                        </div>
                    </div>
                </div>
            }
        </NewLayout>
    )
}

export default AboutTecalis

export const aboutTecalisQuery = graphql`
    query ($langKey: String) {
        page: allStrapi2022SobreTecalis ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    description
                    description_list
                    video_url
                    video_preview{
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    metrics {
                        number
                        name
                    }
                    pillars_title
                    pillars_items {
                        order
                        title
                        description
                    }
                    why_title
                    why_subtitle
                    why_items {
                        order
                        title
                        description
                        icon {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                    }
                    culture_title
                    culture_title_new
                    culture_subtitle
                    culture_items {
                        order
                        title
                        description
                        icon {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 608
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    corporate_policies_title
                    corporate_policies {
                        name
                        description
                        button
                        pdf {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                        link
                    }
                    corporate_policies_image{
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    history_title
                    history_items {
                        order
                        title
                        description
                    }
                }
            }
        }
    }
`
